export const SET_PROFILE = "SET_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const INITIALIZED_SUCCESS = "INITIALIZED_SUCCESS";
export const SET_IS_AUTH = "SET_IS_AUTH";
export const DELETE_IS_AUTH = "DELETE_IS_AUTH";
export const SET_USER_ID = "SET_USER_ID";
export const DELETE_USER_ID = "DELETE_USER_ID";
export const SET_AUTH_IS_LOADING = "SET_AUTH_IS_LOADING";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_DATA = "SET_DATA";
export const SET_TOTAL = "SET_TOTAL";
export const SET_SELECTED_REALTY = "SET_SELECTED_REALTY";
export const DELETE_SELECTED_REALTY = "DELETE_SELECTED_REALTY";
export const SET_IS_NOT_FOUND = "SET_NOT_FOUND";
export const DELETE_IS_NOT_FOUND = "DELETE_IS_NOT_FOUND";
export const SET_REALTY_IS_LOADING = "SET_REALTY_IS_LOADING";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_SORTS = "UPDATE_SORTS";
export const SET_HAS_NEXT_PAGE = "SET_HAS_NEXT_PAGE";
export const SET_NOT_DEFAULT_FILTER = "SET_NOT_DEFAULT_FILTER";
export const SET_OWNER_REALTIES = "SET_OWNER_REALTIES";
export const SET_IS_OWNER_REALTIES_LOADING = "SET_IS_OWNER_REALTIES_LOADING";
export const SET_OWNER_REALTIES_IDS = "SET_OWNER_REALTIES_IDS";
export const UPDATE_PROFILE_IS_LOADING = "UPDATE_PROFILE_IS_LOADING";
export const DELETE_REALTY_IS_FETCHING = "DELETE_REALTY_IS_FETCHING";
export const SET_IS_UPDATING = "SET_IS_UPDATING";
export const DELETE_ALL_WHEN_LOGOUT = "DELETE_ALL_WHEN_LOGOUT";
export const SET_COORDS = "SET_COORDS";
export const DELETE_DATA_ON_CHANGE = "DELETE_DATA_ON_CHANGE";
export const SET_OWNER_PROFILE = "SET_OWNER_PROFILE";
